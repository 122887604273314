body {
  font-family: "Gilroy-Regular", sans-serif;
}

/* Sms font */
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./fonts/Gilroy-Regular.woff2") format("woff2");
}

/* div in index.js */
.container {
  padding-right: 18%;
  padding-left: 18%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 767px) {
  .container {
    padding-right: 5%;
    padding-left: 5%;
    margin-right: auto;
    margin-left: auto;
  }

  .terms-and-conditions {
    font-size: 12px !important;
  }

  .signup-explanation-text {
    font-size: 12px !important;
  }
}

h1,
h2,
h3,
h4,
h5 {
  color: rgb(23, 44, 83) !important;
}

.outlined-button {
  border: 1px solid rgb(23, 44, 83) !important;
  color: rgb(23, 44, 83) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

.full-button {
  border: 1px solid rgb(23, 44, 83) !important;
  color: white !important;
  background-color: rgb(23, 44, 83) !important;
  border-radius: 5px !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

/* This is here because the OSM map does not show on the page without it, something to do with position absolute. TODO: fix */
.fix-this {
  color: white !important;
}

svg {
  color: #172c53;
}

.css-137ul40-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}

button:focus {
  box-shadow: none !important;
  outline: none !important;
}

.datepicker {
  width: 100%;
  height: 100%;
  border: 1px solid #172c53;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-family: "Gilroy-Regular", sans-serif;
  color: #172c53;
  cursor: pointer;
  margin-top: 15px !important;
}

.datepicker button {
  padding-right: 20px !important;
}
